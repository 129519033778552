<template>
  <div :class="['main-container', { collapsed: isCollapsed }]">
    <Sidebar @themeChange="handleThemeChange" @toggle="handleToggle" :collapsed="isCollapsed" />
    <div style="
      height: 100vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    " v-if="loading">
      <LoadingSpinner />
    </div>
    <div v-if="isMainContent && !loading" style="display: flex; justify-content: space-between; align-items: center">
      <div>
        <div class="navigation-info">Autograder</div>
        <p class="welcome-message">Hello, {{ instructorStore.firstName }}</p>
      </div>
      <div class="new-assignment-button" @click="showModal = true">
        New assignment
        <span class="material-symbols-outlined" style="padding-left: 1vh">
          add
        </span>
      </div>
    </div>
    <div :class="['content-container', { collapsed: isCollapsed }]">
      <div v-if="isMainContent">
        <div class="grading-stats">
          <div class="stat-tile">
            <p class="stat-value">{{ gradingStats.graded }}</p>
            <p class="stat-title">Assignments Graded</p>
          </div>
          <div class="stat-tile">
            <p class="stat-value">{{ gradingStats.students }}</p>
            <p class="stat-title">Total Submissions</p>
          </div>
          <div class="stat-tile">
            <p class="stat-value">
              {{ gradingStats.average }}<span style="font-size: 1.5vw">%</span>
            </p>
            <p class="stat-title">Average Score</p>
          </div>
        </div>
        <div class="recent-assignments-header">
          <p>Recent assignments</p>
        </div>
        <div class="previous-assignments-container">
          <div v-if="Object.keys(previousAssignments).length === 0" class="no-assignments-message"
            @click="showModal = true">
            You have no recent assignments. Click to start grading a new
            assignment.
          </div>
          <div v-for="(assignment, index) in sortedAssignments" :key="assignment.ms_id" class="assignment-card"
            @click="goToSubmissionStep(assignment.ms_id)">
            <div class="card-section">
              <div class="card-section-heading">Title</div>
              <div class="card-section-content">
                {{ assignment.title }}
              </div>
            </div>
            <div class="card-section">
              <div class="card-section-heading">Created</div>
              <div class="card-section-content">
                {{ assignment.created }}
              </div>
            </div>
            <div class="card-section">
              <div class="card-section-heading">Due</div>
              <div class="card-section-content">
                {{ assignment.due }}
              </div>
            </div>
            <div class="card-section">
              <div class="card-section-heading">Submissions</div>
              <div class="card-section-content">
                {{ assignment.submissions }}
              </div>
            </div>
            <div class="card-section">
              <div class="card-section-heading">Average</div>
              <div class="card-section-content">
                {{ assignment.average }}%
              </div>
            </div>
          </div>
          <!-- <MarkingSchemeModal
            :isVisible="showModal"
            @nextStep="addAssignment"
            @close="showModal = false"
          /> -->
          <CreateAssignmentPopup v-if="showModal" @add-assignment="addAssignment" @close="showModal = false" />
        </div>
        <!-- Step 2: Upload Submissions -->
        <!-- <SubmissionStep
          v-if="currentStep >= 1"
          :assignmentId="assignmentId"
          :gradedQuestions="gradedQuestions"
          :submissions="submissions"
          @nextStep="goToGradingStep"
        /> -->

        <!-- Step 3: Display Graded Submission -->
        <!-- <GradingStep
          v-if="currentStep === 2"
          :assignmentId="assignmentId"
          :gradedQuestions="gradedQuestions"
          :submissions="submissions"
          :gradingComplete="gradingComplete"
          @gradeSubmissions="gradeSubmissions"
        /> -->
      </div>
      <router-view v-if="!isMainContent"></router-view>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/utils/axiosInstance";
import { convertGMTtoLocal } from "@/utils/generalUtils";
import { useInstructorStore } from "@/store/instructorData";
import CreateAssignmentPopup from "@/components/CreateAssignmentPopup.vue";
import MarkingSchemeModal from "@/components/MarkingSchemeStep.vue";
import SubmissionStep from "@/components/SubmissionStep.vue";
import GradingStep from "@/components/GradingStep.vue";
import Sidebar from "@/components/InstructorSidebar.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "InstructorGrading",
  components: {
    LoadingSpinner,
    Sidebar,
    CreateAssignmentPopup,
    MarkingSchemeModal,
    SubmissionStep,
    GradingStep,
  },
  data() {
    return {
      loading: true,
      previousAssignments: {},
      showModal: false,
      submissions: {},
      assignmentId: -1,
      gradedQuestions: {},
      gradingComplete: false,
      isCollapsed: true,
    };
  },
  created() {
    this.instructorStore = useInstructorStore();
  },
  mounted() {
    this.getPreviousAssignments();
  },
  watch: {
    '$route'() {
      this.getPreviousAssignments();
    }
  },
  computed: {
    gradingStats() {
      const assignments = Object.keys(this.previousAssignments).length;
      var avg = 0
      if (assignments === 0) {
        var avg = 'N/A'
      } else {
        var avg = (Object.values(this.previousAssignments).reduce((total, item) => total + parseFloat(item.average), 0) / Object.values(this.previousAssignments).length).toFixed(2);
      }

      return {
        graded: assignments,
        students: Object.values(this.previousAssignments).reduce((total, item) => total + item.submissions, 0),
        average: avg,
      }
    },
    sortedAssignments() {
      return Object.entries(this.previousAssignments)
        .map(([id, assignment]) => ({ ms_id: id, ...assignment }))
        .sort((a, b) => new Date(b.created) - new Date(a.created));
    },
    isMainContent() {
      return (
        this.$route.path === "/instructor/grader/" ||
        this.$route.path === "/instructor/grader"
      );
    },
  },
  methods: {
    handleToggle(collapsed) {
      this.isCollapsed = !collapsed;
    },
    handleThemeChange(currentTheme) {
      this.theme = currentTheme;
    },
    async getPreviousAssignments() {
      try {
        const response = await axiosInstance.post(
          "/grader/previous-assignments",
          {
            email: this.instructorStore.email,
          }
        );

        for (const assignment of response.data) {
          this.previousAssignments[assignment.ms_id] = {
            title: assignment.title,
            due: convertGMTtoLocal(assignment.due_date),
            created: convertGMTtoLocal(assignment.created_at),
            lastUpdated: assignment.last_updated,
            submissions: assignment.submissions,
            average: (assignment.average || 0).toFixed(2),
          };
        }
      } catch (error) {
        console.error("Error updating response:", error);
      } finally {
        this.loading = false;
      }
    },
    addAssignment(id, created, title, dueDate) {
      this.previousAssignments[id] = {
        title: title,
        created: convertGMTtoLocal(created),
        due: convertGMTtoLocal(dueDate),
        submissions: 0,
        average: 0,
      };

      console.log(this.previousAssignments);
      console.log(Object.keys(this.previousAssignments).length);
    },
    calculateTotalPoints(fileName) {
      this.gradedQuestions[fileName]["grading"] = {
        possible: 0,
        obtained: 0,
      };
      for (const question in this.gradedQuestions[fileName]) {
        if (question !== "grading") {
          this.gradedQuestions[fileName]["grading"].possible += parseFloat(
            this.gradedQuestions[fileName][question].points_possible
          );
          this.gradedQuestions[fileName]["grading"].obtained += parseFloat(
            this.gradedQuestions[fileName][question].points_obtained
          );
        }
      }
    },
    async goToPreviousAssignment(ms_id) {
      console.log(this.submissions);
      try {
        const response = await axiosInstance.post(
          "/grader/previous-questions-and-responses",
          { ms_id: ms_id }
        );
        const data = response.data;
        for (const submission of Object.keys(data)) {
          this.submissions[submission] = { graded: true };
        }
        // this.setCurrentSubmission(Object.keys(this.submissions)[0]);
        this.gradedQuestions = data;

        console.log(this.submissions);
        console.log(this.gradedQuestions);

        // Build points obtained and possible
        for (const fileName in data) {
          this.calculateTotalPoints(fileName);
        }

        this.gradingComplete = true;
        this.currentStep = 2;
        this.assignmentId = ms_id;
      } catch (error) {
        console.error("Error going to assignment:", error);
      }
    },
    goToSubmissionStep(assignmentId) {
      this.assignmentId = assignmentId;
      this.$router.push({
        name: "SubmissionStep",
        params: { assignmentId: assignmentId },
      });
    },
    goToGradingStep() {
      this.gradeSubmissions();
      this.currentStep += 1;
    },
    async gradeSubmissions() {
      this.gradingComplete = false;
      const submissions = Object.keys(this.gradedQuestions);
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/grader/grade-submission`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              marking_scheme_id: this.assignmentId,
              file_names: submissions,
            }),
          }
        );

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          const chunkText = decoder.decode(value, { stream: true });
          const gradedQuestion = JSON.parse(chunkText);
          var fileName = gradedQuestion["file_name"];
          var questionNumber = gradedQuestion["question_number"];
          var response_id = gradedQuestion["response_id"];
          var pointsObtained = gradedQuestion["points_obtained"];
          var pointsPossible = gradedQuestion["points_possible"];
          var feedback = gradedQuestion["feedback"];

          // Check if fully graded
          this.checkGraded(fileName);

          // Calculate totals
          this.gradedQuestions[fileName]["grading"].possible +=
            parseFloat(pointsPossible);
          this.gradedQuestions[fileName]["grading"].obtained +=
            parseFloat(pointsObtained);

          this.gradedQuestions[fileName][questionNumber].response_id =
            response_id;
          this.gradedQuestions[fileName][questionNumber].points_obtained =
            pointsObtained;
          this.gradedQuestions[fileName][questionNumber].points_possible =
            pointsPossible;
          this.gradedQuestions[fileName][questionNumber].feedback = feedback;
          this.gradedQuestions[fileName][questionNumber].original_feedback =
            feedback;
        }

        this.gradingComplete = true;
      } catch (error) {
        console.error("Error grading submission:", error);
      }
    },
  },
};
</script>

<style scoped>
.main-container {
  transition: all 0.3s ease-in-out;
  font-family: var(--main-font);
  overflow: hidden;
  margin-right: 2vw;
}

.main-container.collapsed {
  margin-left: 8vw;
}

.main-container:not(.collapsed) {
  margin-left: 22vw;
}

.content-container {
  flex: 1;
  transition: margin-left 0.3s ease-in-out;
  color: var(--primary);
  /* margin: 0 2vw; */
  overflow: hidden;
}

.navigation-info {
  color: grey;
  margin-top: 4vh;
  font-size: 1.2vw;
}

.material-symbols-outlined {
  font-size: 1.5vw;
}

.new-assignment-button {
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 1.5vh 2vw;
  margin-top: 4vh;
  font-size: 1.3vw;
  background-color: var(--accent4);
  color: var(--secondary);
  border-radius: 2rem;
  box-shadow: var(--box-shadow);
  cursor: pointer;
  transition: ease all 0.3s;
}

.new-assignment-button:hover {
  background-color: var(--classi2);
  color: white;
}

.new-assignment-button:active {
  transform: scale(90%);
}

.welcome-message {
  font-size: 3vw;
  /* margin-top: 0.2vh; */
}

.grading-stats {
  width: 100%;
  height: 15vh;
  margin: 3vh 0 1vh 0;
  display: flex;
  justify-content: space-evenly;
}

.stat-tile {
  width: 18vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
}

.stat-tile:hover {
  box-shadow: var(--box-shadow);
  transform: translateY(-5%);
  transition: ease all 0.5s;
}

.stat-value {
  font-size: 5vw;
}

.stat-title {
  font-size: 1vw;
  color: grey;
  margin-bottom: 2vh;
}

.previous-assignments-container {
  width: 100%;
  height: 55vh;
  background-color: rgb(211, 211, 211);
  background-color: rgb(0, 139, 139, 0.8);
  border-radius: 2rem;
  font-size: 1.2vw;
  padding: 2vh 2vw;
}

.no-assignments-message {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}

.no-assignments-message:active {
  opacity: 0.2;
  transition: ease all 0.2s;
}

.recent-assignments-header {
  color: var(--primary);
  font-size: 1.5vw;
  padding: 1vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.assignment-card {
  width: 100%;
  background-color: var(--accent3);
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
  padding: 1.5vh 1.5vw;
  margin-bottom: 1vh;
  display: flex;
}

.assignment-card:hover {
  cursor: pointer;
  transform: translateY(-5%);
  transition: ease all 0.3s;
}

.card-section {
  flex: 1;
}

.card-section-heading {
  color: var(--accent2);
  font-size: 0.9vw;
}
</style>

<template>
    <div :class="['main-container', { collapsed: isCollapsed }]">
        <Sidebar @toggle="handleToggle" :collapsed="isCollapsed" />
        <div :class="['content-container', { collapsed: isCollapsed }]">
            <StudentHeader title="Grades" />
            <LoadingSpinner class="spinner" v-if="loading" />
            <div v-if="isMainContent" class="content">
                <GradesLineChart
                    v-if="!loading"
                    :chartData="chartData"
                    :assignments="gradedAssignments"
                    :class="['grades-chart', { 'course-clicked': clicked }]"
                    @pointClicked="handlePointClick"
                />

                <div ref="scrollRef"></div>
                <div class="grades-subject-container">
                    <div
                        v-for="(course, index) in Array.from(
                            new Set(
                                gradedAssignments.map(
                                    (assignment) => assignment.course
                                )
                            )
                        )"
                        :class="[
                            'grade-subject',
                            { clicked: clicked === course },
                        ]"
                        :key="index"
                        @click="handleCourseClick(course)"
                    >
                        <div
                            class="grade-subject-title"
                            :style="getCourseStyle(course)"
                        >
                            {{ course }}
                        </div>
                        <div class="grade-subject-average">
                            {{ averages[course] }}%
                        </div>
                        <div
                            v-for="(
                                assignment, index
                            ) in gradedAssignments.filter(
                                (assignment) => assignment.course === course
                            )"
                            :key="index"
                        >
                            <div v-if="assignment.course === clicked">
                                <div
                                    class="grade-card"
                                    @click="
                                        goToAssignment(assignment.assignment_id)
                                    "
                                >
                                    <div class="grade-title">
                                        {{ assignment.title }}
                                    </div>
                                    <div class="grade">
                                        <span
                                            :style="'color:' + colors[course]"
                                        >
                                            {{
                                                parseFloat(
                                                    assignment.marks_obtained
                                                ).toFixed(2)
                                            }}
                                        </span>
                                        /
                                        {{ assignment.marks_total.toFixed(2) }}
                                        ({{
                                            (
                                                (parseFloat(
                                                    assignment.marks_obtained
                                                ) *
                                                    100) /
                                                assignment.marks_total
                                            ).toFixed(2)
                                        }}%)
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StudentHeader from "@/components/StudentHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import GradesLineChart from "@/components/GradesLineChart.vue";
import axiosInstance from "@/utils/axiosInstance";
import { useStudentStore } from "@/store/studentData";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useAssignmentStore } from "@/store/assignmentData";

export default {
    name: "StudentClasses",
    components: {
        StudentHeader,
        Sidebar,
        GradesLineChart,
        LoadingSpinner,
    },
    data() {
        return {
            isCollapsed: false,
            loading: true,
            gradedAssignments: [],
            percentages: {},
            clicked: "",
            colors: {
                Physics: "#28a745",
                History: "#6f42c1",
                English: "#fd7e14",
                Science: "#17a2b8",
                Mathematics: "magenta",
            },
        };
    },
    computed: {
        studentStore() {
            return useStudentStore();
        },
        isMainContent() {
            return this.$route.path === "/student/grades";
        },
        chartData() {
            const courses = {};
            let minPercentage = 100;
            let maxPercentage = 0;

            this.gradedAssignments.forEach((assignment) => {
                const percentage =
                    (parseFloat(assignment.marks_obtained) /
                        assignment.marks_total) *
                    100;
                if (assignment.course in this.percentages) {
                    this.percentages[assignment.course].push(percentage);
                } else {
                    this.percentages[assignment.course] = [percentage];
                }

                const date = new Date(assignment.due_date);
                const title = assignment.title;
                if (!courses[assignment.course]) {
                    courses[assignment.course] = [];
                }
                const id = assignment.assignment_id;
                courses[assignment.course].push({
                    id,
                    title,
                    date,
                    percentage,
                });
            });

            // Sort data points by date for each course
            Object.keys(courses).forEach((course) => {
                courses[course].sort((a, b) => a.date - b.date);
            });

            // Create datasets for the chart
            const datasets = Object.keys(courses).map((course) => ({
                label: course,
                data: courses[course].map((dataPoint) => ({
                    id: dataPoint.id,
                    title: dataPoint.title,
                    x: dataPoint.date,
                    y: dataPoint.percentage,
                })),
                fill: false,
                // borderColor: this.getRandomColor(),
                borderColor: this.getColor(course),
                tension: 0.4,
                borderWidth: 1.5,
            }));

            return {
                datasets,
            };
        },
        averages() {
            const averages = {};
            for (let [course, pcts] of Object.entries(this.percentages)) {
                const getAvgs = (array) =>
                    array.reduce((a, b) => a + b) / array.length;
                averages[course] = getAvgs(pcts).toFixed(2);
            }
            return averages;
        },
    },
    methods: {
        handleToggle(collapsed) {
            this.isCollapsed = !collapsed;
        },
        handlePointClick(datasetIndex, pointIndex) {
            const course = this.chartData.datasets[datasetIndex];
            const assignment = course.data[pointIndex];
            this.goToAssignment(assignment.id);
        },
        async getAllGrades() {
            const gradesResponse = await axiosInstance.get(
                "/students/get-all-grades"
            );

            this.gradedAssignments = gradesResponse.data;
            this.loading = false;
        },
        handleCourseClick(course) {
            if (this.clicked === course) {
                this.clicked = "";
            } else {
                this.clicked = course;
            }
            this.$nextTick(() => {
                const scrollRef = this.$refs.scrollRef;
                if (scrollRef) {
                    scrollRef.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                    });
                }
            });
        },
        getCourseStyle(course) {
            return {
                borderBottomColor: this.colors[course] || "grey",
                "--hover-color": this.colors[course] || "#28282B",
            };
        },
        getRandomColor() {
            const letters = "0123456789ABCDEF";
            let color = "#";
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
        getColor(course) {
            console.log(course);
            const colors = {
                Physics: "#28a745",
                History: "#6f42c1",
                English: "#fd7e14",
                Science: "#17a2b8",
                Mathematics: "magenta",
            };

            return colors[course];
        },
        goToAssignment(id) {
            this.$router.push({
                name: "StudentAssignment",
                params: { assignmentId: id },
            });
        },
    },
    mounted() {
        this.getAllGrades();
    },
};
</script>

<style scoped>
.main-container {
    display: flex;
    transition: all 0.3s ease-in-out;
    font-family: var(--main-font);
    margin: 2vh 2vw;
}

.main-container.collapsed .content-container {
    margin-left: 5vw;
}

.main-container:not(.collapsed) .content-container {
    margin-left: 18vw;
}

.content-container {
    flex: 1;
    transition: margin-left 0.3s ease-in-out;
    color: var(--primary);
}

.spinner {
    margin: 20vh auto;
}

.content {
    display: flex;
    flex-direction: column;
}

.grades-chart {
    margin: 2rem;
    height: 40vh;
    width: 70vw;
}

.grades-subject-container {
    margin-top: 6vh;
    margin-bottom: 3vh;
    width: 70vw;
    display: flex;
    justify-content: space-evenly;
    font-size: 1.3vw;
}

.grade-subject {
    cursor: pointer;
    margin: 1rem;
    width: 15vw;
    transition: all ease 0.3s;
}

.grade-subject-title:hover {
    background-color: var(--hover-color);
}

.grade-subject.clicked {
    width: 500%;
}

.grade-subject-title {
    padding: 2rem 0.5rem;
    text-align: center;
    border-bottom: 0.3rem solid;
    transition: all ease 0.3s;
}

.grade-subject-average {
    font-size: 1vw;
    padding: 2rem 0.5rem;
    color: var(--accent2);
    text-align: center;
    transition: all ease 0.3s;
}

.grade-subject-average:hover {
    background-color: var(--primary);
    color: var(--secondary);
}

.grade-card {
    display: flex;
    align-items: center;
    height: 7vh;
    margin: auto;
    padding: 1rem;
    margin-top: 2rem;
    border: none;
    border-bottom: 0.1rem solid var(--primary);
    transition: all ease 0.3s;
}

.grade-card:hover {
    cursor: pointer;
    transform: translateY(-1rem);
    background-color: var(--primary);
    color: var(--secondary);
}

.grade-title {
    flex: 8;
    margin: 0rem 2rem;
    font-size: 1.1vw;
}

.grade {
    text-align: right;
    flex: 3;
    font-size: 0.9vw;
}
</style>

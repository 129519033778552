import axios from "axios";

const axiosInstance = axios.create({    
    baseURL: process.env.VUE_APP_API_BASE_URL,
    withCredentials: true,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const csrfToken = document.cookie
            .split(";")
            .find((item) => item.trim().startsWith("csrf_access_token="));
        if (csrfToken) {
            config.headers["X-CSRF-TOKEN"] = csrfToken.split("=")[1];
        }
        // console.log(document.cookie);
        // console.log(csrfToken);
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;

<template>
  <div class="subject-textbook-picker">
    <div class="dropdown-container">
      <!-- Show the subject name or "Pick a subject..." -->
      <div
        class="dropdown-label"
        @click="toggleDropdown"
        :class="{ locked: subjectLocked }"
      >
        <span>{{ currentSubject || "Pick a subject..." }}</span>
        <!-- Lock icon if locked, arrow if unlocked -->
        <span v-if="subjectLocked" class="lock-icon">🔒</span>
        <span v-else class="arrow">{{ dropdownOpen ? "▲" : "▼" }}</span>
      </div>
      <transition name="fade">
        <ul
          v-if="dropdownOpen && !subjectLocked"
          class="dropdown-list"
        >
          <li
            v-for="(books, subject) in groupedBySubject"
            :key="subject"
            class="dropdown-item"
            @click.stop="chooseSubject(subject)"
          >
            {{ subject }}
          </li>
        </ul>
      </transition>
    </div>

    <!-- Checkboxes for the chosen subject -->
    <div v-if="currentSubject" class="checkbox-list">
      <label
        v-for="book in groupedBySubject[currentSubject]"
        :key="book.textbook_id"
        class="checkbox-item"
      >
        <input
          type="checkbox"
          :value="book.textbook_id"
          v-model="checkedTextbookIds"
          @change="onCheckChange"
        />
        <span class="custom-checkbox"></span>
        <span class="checkbox-label">{{ book.title }}</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubjectTextbookPicker",
  props: {
    textbooks: {
      type: Array,
      required: true,
      // e.g. [{ textbook_id, title, subject, example_queries }, ...]
    },
    initialSubject: {
      type: String,
      default: null,
    },
    initialCheckedIds: {
      type: Array,
      default: () => [],
    },
    subjectLocked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dropdownOpen: false,
      currentSubject: null,
      checkedTextbookIds: [],
    };
  },
  computed: {
    groupedBySubject() {
      const map = {};
      for (const tb of this.textbooks) {
        if (!map[tb.subject]) {
          map[tb.subject] = [];
        }
        map[tb.subject].push(tb);
      }
      return map;
    },
  },
  methods: {
    toggleDropdown() {
      if (this.subjectLocked) return;
      this.dropdownOpen = !this.dropdownOpen;
    },
    chooseSubject(subject) {
      this.currentSubject = subject;
      this.dropdownOpen = false;
      // Remove any textbooks not in this subject
      const validIds = (this.groupedBySubject[subject] || []).map(
        (b) => b.textbook_id
      );
      this.checkedTextbookIds = this.checkedTextbookIds.filter((id) =>
        validIds.includes(id)
      );
      this.emitChange();
    },
    onCheckChange() {
      // Enforce at least one textbook
      if (this.checkedTextbookIds.length === 0) {
        const validIds = (this.groupedBySubject[this.currentSubject] || []).map(
          (b) => b.textbook_id
        );
        if (validIds.length > 0) {
          // Re-check the first valid one
          this.checkedTextbookIds = [validIds[0]];
        }
      }
      this.emitChange();
    },
    emitChange() {
      this.$emit("subject-textbooks-changed", {
        subject: this.currentSubject,
        textbookIds: this.checkedTextbookIds,
      });
    },
    updateSelection({ newSubject, newCheckedIds }) {
      this.currentSubject = newSubject || null;
      this.checkedTextbookIds = newCheckedIds ? [...newCheckedIds] : [];
      // If there's a subject but no textbooks => pick at least one
      if (this.currentSubject && this.checkedTextbookIds.length === 0) {
        const validIds = (this.groupedBySubject[this.currentSubject] || []).map(
          (b) => b.textbook_id
        );
        if (validIds.length > 0) {
          this.checkedTextbookIds = [validIds[0]];
        }
      }
    },
  },
  mounted() {
    // Initialize
    if (this.initialSubject) {
      this.currentSubject = this.initialSubject;
    }
    this.checkedTextbookIds = [...this.initialCheckedIds];
    if (this.currentSubject && this.checkedTextbookIds.length === 0) {
      const validIds = (this.groupedBySubject[this.currentSubject] || []).map(
        (b) => b.textbook_id
      );
      if (validIds.length > 0) {
        this.checkedTextbookIds = [validIds[0]];
      }
    }
  },
};
</script>

<style scoped>
.subject-textbook-picker {
  font-size: 1vw;
  margin-bottom: 2vh;
}

/* Dropdown container */
.dropdown-container {
  position: relative;
  margin-bottom: 1vw;
  user-select: none;
}
.dropdown-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 14vw;
  padding: 0.3vw 0.5vw;
  border: 1px solid #ccc;
  border-radius: 0.4vw;
  background-color: #f9f9f9;
  cursor: pointer;
}
.dropdown-label.locked {
  background-color: #eee;
  color: #999;
  cursor: not-allowed;
}
.lock-icon {
  margin-left: 0.5vw;
}
.arrow {
  margin-left: 0.5vw;
}
.dropdown-list {
  position: absolute;
  top: 2.5vw;
  left: 0;
  width: 14vw;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0.4vw;
  max-height: 20vw;
  overflow-y: auto;
  z-index: 999;
}
.dropdown-item {
  padding: 0.3vw 0.5vw;
  cursor: pointer;
}
.dropdown-item:hover {
  background-color: #eee;
}

/* Fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Checkboxes */
.checkbox-list {
  display: flex;
  flex-direction: column;
  margin-top: 1vw;
}

.checkbox-item {
  margin: 0.4rem 0;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

/* Hidden real checkbox */
.checkbox-item input[type="checkbox"] {
  position: absolute;
  left: 0;
  opacity: 0;
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
  z-index: 2;
}

/* Styled box */
.custom-checkbox {
  width: 1.2rem;
  height: 1.2rem;
  border: 2px solid #999;
  border-radius: 0.2rem;
  margin-right: 0.6rem;
  background-color: #fff;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-checkbox::after {
  content: "✔";
  font-size: 1rem;
  color: #fff;
  opacity: 0;
  transition: opacity 0.2s;
}
.checkbox-item input[type="checkbox"]:checked ~ .custom-checkbox {
  background-color: #0080ff;
  border-color: #0080ff;
}
.checkbox-item input[type="checkbox"]:checked ~ .custom-checkbox::after {
  opacity: 1;
}
.checkbox-label {
  font-size: 1rem;
  user-select: none;
  transition: color 0.2s;
}
.checkbox-label:hover {
  color: #333;
}
</style>
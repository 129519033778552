<template>
    <div class="page-container">
        <!-- Feedback Modal -->
        <div v-if="showFeedbackModal" class="feedback-modal-overlay">
            <div class="feedback-modal">
                <span class="close" @click="closeFeedbackModal">✕</span>
                <p style="font-size: 1.5vw; margin-bottom: 2vh;">Feedback</p>
                <textarea v-model="feedbackInput" placeholder="Provide feedback or report a bug"></textarea>
                <button @click="submitFeedback">Submit</button>
            </div>
        </div>

        <!-- Header -->
        <div class="header">
            <div style="flex: 1; display: flex; justify-content: left;">
                <router-link to="/" class="logo">
                    <span class="classi1">c</span>
                    <span class="classi1">l</span>
                    <span class="classi2">a</span>
                    <span class="classi1">s</span>
                    <span class="classi1">s</span>
                    <span class="classi2">i</span>
                    <span class="beta-badge">BETA</span>
                </router-link>
            </div>
            <!-- <div style="flex: 1; display: flex; justify-content: center;">
                <img :src="schoolLogo" class="school-logo" @click="goToSchoolWebsite" />
            </div> -->
            <div style="flex: 1; display: flex; justify-content: right; font-size: 2vw;">
                <!-- <div class="feedback-button" title="Provide Feedback" @click="showFeedbackModal = true">
                    <span class="material-symbols-outlined">feedback</span>
                </div> -->
                <div class="logout-button" title="Logout" @click="logout">
                    <span class="material-symbols-outlined">logout</span>
                </div>
            </div>
        </div>

        <!-- Main content area: Collapsible sidebar + Chat content -->
        <div class="main-container">
            <div class="sidebar" :class="{ collapsed: isSidebarCollapsed }">
                <button class="collapse-btn" @click="toggleSidebar">
                    <span v-if="!isSidebarCollapsed">
                        <span class="material-symbols-outlined">left_panel_close</span>
                    </span>
                    <span v-else>
                        <span class="material-symbols-outlined">left_panel_open</span>
                    </span>
                </button>

                <!-- Subject & textbooks picker -->
                <SubjectTextbookPicker v-if="!isSidebarCollapsed" ref="subjectPicker" :textbooks="allTextbooks"
                    :initialSubject="null" :initialCheckedIds="[]" :subjectLocked="currentChatHasMessages"
                    @subject-textbooks-changed="onSubjectTextbooksChanged" />

                <div class="sidebar-header" v-if="!isSidebarCollapsed">Conversations</div>
                <div v-if="isLoadingConversations && !isSidebarCollapsed" class="spinner-container">
                    <LoadingSpinner />
                </div>
                <ul v-else class="chatList" v-if="!isSidebarCollapsed">
                    <li v-for="(chat, index) in chatHistories" :key="chat.id"
                        :class="{ active: currentChatIndex === index }">
                        <div class="chatItem" @click="switchChat(index)">
                            <input v-if="editingChatIndex === index" v-model="editingChatTitle"
                                @blur="saveChatTitle(index)" @keyup.enter="saveChatTitle(index)"
                                @keyup.esc="editingChatIndex = null" ref="chatTitleInput" class="chat-title-input"
                                :maxlength="50" @click.stop />
                            <span v-else>{{ chat.title }}</span>
                        </div>
                        <div class="chatActions">
                            <span class="material-symbols-outlined rename-icon" title="Rename"
                                @click.stop="renameChat(index)">
                                edit
                            </span>
                            <span class="material-symbols-outlined delete-icon" title="Delete"
                                @click.stop="deleteChat(index)">
                                delete
                            </span>
                        </div>
                    </li>
                </ul>
                <button class="newChatBtn" :disabled="chatHistories.length >= 10" :title="chatHistories.length >= 10
                    ? 'You have reached the maximum of 10 chats. Please delete at least one chat to create a new one.'
                    : ''
                    " @click="createNewChat" v-if="!isSidebarCollapsed">
                    + New Chat
                </button>
            </div>

            <!-- Chat content area -->
            <div class="contentArea">
                <div class="chatContainer" style="position: relative;">
                    <div ref="chatOutput" class="chatOutput">
                        <div v-if="isLoadingMessages" class="spinner-container">
                            <LoadingSpinner />
                        </div>
                        <template v-else>
                            <!-- If no interactions, show initial message -->
                            <div v-if="currentInteractions.length === 0" class="initial-message">
                                <h1>Hello, {{ studentStore.firstName }}. I am <span class="aura">AURA.</span></h1>
                                <div class="byline"><i>What would you like to learn about today?</i></div>
                                <div class="byline2">

                                    Selected Textbooks: {{
                                        currentChat?.textbookIds?.map(id =>
                                            allTextbooks.find(b => b.textbook_id === id)?.title
                                        ).join(', ') || 'No textbooks selected'
                                    }}

                                </div>
                                <div class="example-queries">
                                    <ul>
                                        <li v-for="query in mergedExampleQueries" :key="query" class="example-query"
                                            @click="input = query">
                                            <span class="icon">💡</span> {{ query }}
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- If we have interactions, display them -->
                            <div v-for="(interaction, idx) in currentInteractions" :key="idx">
                                <p v-if="interaction.sent" class="sentBox" v-html="formatReceived(interaction.sent)">
                                </p>
                                <p v-if="interaction.received" class="receivedBox"
                                    v-html="formatReceived(interaction.received)"></p>
                            </div>

                            <!-- Typing indicator -->
                            <div v-if="isWaitingForResponse" class="receivedBox typing-indicator">
                                <span class="ellipsis"><span>.</span><span>.</span><span>.</span></span>
                            </div>
                        </template>
                    </div>

                    <!-- Input row -->
                    <div class="inputRow">
                        <div class="inputWrapper" style="position: relative;">
                            <textarea v-model="input" class="chatInput" ref="textarea"
                                style="position: absolute; bottom: 0; top: auto; transform-origin: bottom;"
                                placeholder="Chat with your textbook..." @keydown="clickSendButton"
                                @input="adjustTextareaHeight"></textarea>
                            <span v-if="speechRecognitionSupported" @click="toggleDictation"
                                class="material-symbols-outlined dictationButton" :class="{ recording: isDictating }">
                                mic
                            </span>
                        </div>
                        <span ref="sendButton" :disabled="isSendDisabled" @click="sendMessage"
                            class="material-symbols-outlined sendButton">
                            send
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import io from "socket.io-client";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { throttle } from "lodash";

import { useStudentStore } from "@/store/studentData";
import axiosInstance from "@/utils/axiosInstance";
import SubjectTextbookPicker from "@/components/SubjectTextbookPicker.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
    name: "IsolatedChat",
    components: {
        SubjectTextbookPicker,
        LoadingSpinner,
    },
    data() {
        return {
            // schoolLogo: require("@/assets/dpsrkp.png"),

            // Feedback
            showFeedbackModal: false,
            feedbackInput: "",

            // Book data
            allTextbooks: [],

            // Conversations
            chatHistories: [],
            currentChatIndex: 0,

            // Chat UI
            input: "",
            isButtonDisabled: false,
            isWaitingForResponse: false,
            isDictating: false,
            speechRecognition: null,
            speechRecognitionSupported: false,
            socket: null,
            isSidebarCollapsed: false,
            isTypesetting: false,

            // For error handling/timeouts
            requestTimeout: null,
            editingChatIndex: null,
            editingChatTitle: "",

            isLoadingConversations: false,
            isLoadingMessages: false,
        };
    },
    computed: {
        studentStore() {
            return useStudentStore();
        },
        currentChat() {
            return this.chatHistories[this.currentChatIndex] || null;
        },
        currentInteractions() {
            return this.currentChat?.interactions || [];
        },
        currentChatHasMessages() {
            return this.currentInteractions.length > 0;
        },
        isSendDisabled() {
            const hasNoTextbooks =
                !this.currentChat ||
                !this.currentChat.textbookIds ||
                this.currentChat.textbookIds.length === 0;
            return this.isButtonDisabled || hasNoTextbooks || this.input.trim() === "";
        },
        mergedExampleQueries() {
            if (!this.currentChat || !this.currentChat.subject) return [];
            const subject = this.currentChat.subject;
            const tbIds = this.currentChat.textbookIds || [];

            // Only get examples from the first textbook
            if (tbIds.length === 0) return [];
            const firstBookId = tbIds[0];
            const book = this.allTextbooks.find(
                (tb) => tb.textbook_id === firstBookId && tb.subject === subject
            );
            return book?.example_queries || [];
        },
    },
    created() {
        this.initializeSocket();
        this.checkSpeechRecognitionSupport();
        this.fetchAllTextbooks();
        this.fetchConversations();
    },
    methods: {
        /* ---------------------- Feedback Modal ---------------------- */
        closeFeedbackModal() {
            this.showFeedbackModal = false;
        },
        submitFeedback() {
            this.feedbackInput = "";
            this.showFeedbackModal = false;
        },

        /* ---------------------- Subject & Textbooks ---------------------- */
        async fetchAllTextbooks() {
            try {
                const resp = await axiosInstance.get("/chatbot/list-textbooks");
                this.allTextbooks = resp.data || [];
            } catch (err) {
                console.error("Error fetching textbooks:", err);
                alert("Failed to load textbooks. Please try again later.");
            }
        },
        onSubjectTextbooksChanged({ subject, textbookIds }) {
            if (!this.currentChat) return;

            // If subject changed and no textbooks selected, auto-select first textbook of new subject
            if (subject !== this.currentChat.subject && (!textbookIds || textbookIds.length === 0)) {
                const firstBookInSubject = this.allTextbooks.find(tb => tb.subject === subject);
                if (firstBookInSubject) {
                    textbookIds = [firstBookInSubject.textbook_id];
                    // Update the picker component
                    this.$nextTick(() => {
                        this.$refs.subjectPicker.updateSelection({
                            newSubject: subject,
                            newCheckedIds: textbookIds,
                        });
                    });
                }
            }

            this.currentChat.subject = subject;
            this.currentChat.textbookIds = textbookIds;
        },

        /* ---------------------- Conversations ---------------------- */
        async fetchConversations() {
            this.isLoadingConversations = true;
            try {
                const resp = await axiosInstance.post("/chatbot/get-conversations", {
                    studentId: this.studentStore.studentId,
                });
                let data = resp.data || [];

                // If no existing convos, create a default new chat
                if (data.length === 0) {
                    const firstBook = this.allTextbooks[0];
                    data = [
                        {
                            conversation_id: -1,
                            conversation_title: "New Chat",
                            subject: firstBook?.subject || null,
                            textbookIds: firstBook ? [firstBook.textbook_id] : [],
                        },
                    ];
                }

                this.chatHistories = data.map((c) => ({
                    id: c.conversation_id,
                    title: c.conversation_title,
                    conversation_created: c.conversation_created,
                    subject: c.subject || null,
                    textbookIds: c.textbookIds || [], // Now properly handled as array
                    interactions: [],
                }));

                // Sort conversations by creation date
                this.chatHistories.sort(
                    (a, b) => new Date(b.conversation_created) - new Date(a.conversation_created)
                );

                this.currentChatIndex = 0;
                await this.loadConversation(0);
            } catch (err) {
                console.error("Error fetching conversations:", err);
                alert("Failed to load conversations. Please try again later.");
            } finally {
                this.isLoadingConversations = false;
            }
        },
        async loadConversation(index) {
            const chat = this.chatHistories[index];
            if (!chat) return;
            // Update the child
            this.$nextTick(() => {
                this.$refs.subjectPicker.updateSelection({
                    newSubject: chat.subject,
                    newCheckedIds: chat.textbookIds,
                });
            });

            // If saved conversation, fetch messages
            if (chat.id !== -1) {
                this.isLoadingMessages = true;
                try {
                    const resp = await axiosInstance.post("/chatbot/get-conversation-chats", {
                        conversation_id: chat.id,
                    });
                    const msgs = resp.data || [];
                    chat.interactions = msgs.map((m) => ({
                        sent: m.query,
                        received: m.response,
                    }));
                } catch (err) {
                    console.error("Error fetching messages:", err);
                    alert("Failed to load messages for this conversation. Please try again later.");
                } finally {
                    this.isLoadingMessages = false;
                }
            }
        },
        switchChat(index) {
            if (this.isWaitingForResponse) {
                alert("Cannot switch chat while waiting for a response.");
                return;
            }
            this.currentChatIndex = index;
            this.loadConversation(index);
        },

        /**
         * Creates a new chat and:
         * 1. Copies subject/textbooks from the current chat if they exist.
         * 2. Otherwise defaults to the first subject + first textbook in `allTextbooks`.
         */
        createNewChat() {
            if (this.chatHistories.length >= 10) return;

            // 1) Attempt to copy subject + textbooks from the current chat
            let newSubject = null;
            let newTextbooks = [];
            const current = this.currentChat;

            if (current && current.subject && current.textbookIds?.length) {
                newSubject = current.subject;
                newTextbooks = [...current.textbookIds];
            }

            // 2) If no existing subject or textbooks, fallback to the first in the list
            if ((!newSubject || newTextbooks.length === 0) && this.allTextbooks.length > 0) {
                // Just pick the first textbook's subject
                const firstBook = this.allTextbooks[0];
                newSubject = firstBook.subject;
                newTextbooks = [firstBook.textbook_id];
            }

            const newChat = {
                id: -1,
                title: "New Chat",
                subject: newSubject,
                textbookIds: newTextbooks,
                interactions: [],
            };

            this.chatHistories.unshift(newChat);
            this.currentChatIndex = 0;

            // Make sure the child picker reflects these
            this.$nextTick(() => {
                this.$refs.subjectPicker.updateSelection({
                    newSubject,
                    newCheckedIds: newTextbooks,
                });
            });
        },

        renameChat(index) {
            if (this.isWaitingForResponse) {
                alert("Cannot rename chat while waiting for a response.");
                return;
            }
            const chat = this.chatHistories[index];
            this.editingChatTitle = chat.title || "New Chat";
            this.editingChatIndex = index;
        },
        async saveChatTitle(index) {
            const chat = this.chatHistories[index];
            const newTitle = this.editingChatTitle.trim();

            if (newTitle && newTitle !== chat.title) {
                try {
                    await axiosInstance.post("/chatbot/rename-conversation", {
                        conversationId: chat.id,
                        conversationTitle: newTitle,
                    });
                    chat.title = newTitle;
                } catch (err) {
                    console.error("Failed to rename chat:", err);
                    alert("Failed to rename. Please try again later.");
                }
            }
            this.editingChatIndex = null;
        },
        deleteChat(index) {
            if (this.isWaitingForResponse) {
                alert("Cannot delete chat while waiting for a response.");
                return;
            }
            const chat = this.chatHistories[index];
            const currentName = chat.title || "New Chat";
            if (!confirm(`Are you sure you want to delete "${currentName}"?`)) {
                return;
            }
            if (chat.id !== -1) {
                axiosInstance
                    .post("/chatbot/delete-conversation", {
                        conversationId: chat.id,
                    })
                    .then(() => {
                        this.chatHistories.splice(index, 1);
                        if (this.chatHistories.length === 0) {
                            // Create a new default chat if this was the last one
                            const firstBook = this.allTextbooks[0];
                            const newChat = {
                                id: -1,
                                title: "New Chat",
                                subject: firstBook?.subject || null,
                                textbookIds: firstBook ? [firstBook.textbook_id] : [],
                                interactions: [],
                            };
                            this.chatHistories.push(newChat);
                        }

                        // Update current index and load the conversation
                        this.currentChatIndex = Math.min(index, this.chatHistories.length - 1);
                        this.loadConversation(this.currentChatIndex);
                    })
                    .catch((err) => {
                        console.error("Failed to delete chat:", err);
                        alert("Failed to delete. Please try again later.");
                    });
            } else {
                this.chatHistories.splice(index, 1);
                if (this.chatHistories.length === 0) {
                    // Create a new default chat if this was the last one
                    const firstBook = this.allTextbooks[0];
                    const newChat = {
                        id: -1,
                        title: "New Chat",
                        subject: firstBook?.subject || null,
                        textbookIds: firstBook ? [firstBook.textbook_id] : [],
                        interactions: [],
                    };
                    this.chatHistories.push(newChat);
                }

                // Update current index and load the conversation
                this.currentChatIndex = Math.min(index, this.chatHistories.length - 1);
                this.loadConversation(this.currentChatIndex);
            }
        },

        /* ---------------------- Messaging ---------------------- */
        async sendMessage() {
            if (this.isSendDisabled) return;

            this.isButtonDisabled = true;
            this.isWaitingForResponse = true;

            this.currentInteractions.push({ sent: this.input, received: "" });
            this.scrollToBottom();

            const chat = this.currentChat;
            // If not saved, create the conversation
            if (chat.id === -1) {
                try {
                    const resp = await axiosInstance.post("chatbot/new-conversation", {
                        title: chat.title,
                        userId: this.studentStore.studentId,
                        subject: chat.subject,
                        textbookIds: chat.textbookIds,
                    });
                    chat.id = resp.data.conversation_id;
                } catch (err) {
                    console.error("Error creating conversation:", err);
                    alert("Failed to create new conversation. Please try again later.");
                    this.isWaitingForResponse = false;
                    this.isButtonDisabled = false;
                    return;
                }
            }

            // Simple request timeout
            this.requestTimeout = setTimeout(() => {
                if (this.isWaitingForResponse) {
                    this.isWaitingForResponse = false;
                    this.isButtonDisabled = false;
                    alert("No response from the server. Please try again later.");
                }
            }, 30000);

            // Send via socket
            this.socket.emit("message", {
                conversationId: chat.id,
                conversationTitle: chat.title,
                userId: this.studentStore.studentId,
                firstName: this.studentStore.firstName,
                subject: chat.subject,
                textbookIds: chat.textbookIds,
                query: this.input,
            });

            this.input = "";
        },
        clickSendButton(event) {
            if (event.key === "Enter" && !event.shiftKey && this.input.trim() !== "") {
                event.preventDefault();
                this.$refs.sendButton.click();
                this.$refs.textarea.style.height = "6vh";
            }
        },

        /* ---------------------- Socket ---------------------- */
        initializeSocket() {
            this.socket = io(process.env.VUE_APP_API_BASE_URL, {
                transports: ["polling", "websocket"],
                upgrade: true,
                withCredentials: true,
                extraHeaders: {
                    "X-CSRF-TOKEN": document.cookie
                        .split(";")
                        .find((item) => item.trim().startsWith("csrf_access_token="))
                        ?.split("=")[1] || ""
                }
            });
            this.socket.on("connect", () => {
                console.log("WebSocket connected");
            });
            this.socket.on("response", (data) => {
                clearTimeout(this.requestTimeout);
                this.isWaitingForResponse = false;
                if (data.message === "[END]") {
                    this.isButtonDisabled = false;
                } else {
                    const last = this.currentInteractions[this.currentInteractions.length - 1];
                    if (last) {
                        last.received += data.message;
                    }
                    this.scrollToBottom();
                }
            });
            this.socket.on("disconnect", () => {
                console.log("WebSocket disconnected");
            });
            this.socket.on("connect_error", (error) => {
                console.error("WebSocket connection error:", error);
                alert("Could not connect to the server. Please check your network.");
            });
        },

        /* ---------------------- Logout & Nav ---------------------- */
        async logout() {
            await this.studentStore.logout();
            this.$router.push("/login");
        },
        goToSchoolWebsite() {
            window.open("https://dpsrkp.net", "_blank");
        },

        /* ---------------------- UI Helpers ---------------------- */
        toggleSidebar() {
            console.log(this.isSidebarCollapsed)
            this.isSidebarCollapsed = !this.isSidebarCollapsed;
        },
        adjustTextareaHeight(event) {
            const textarea = event.target;
            textarea.style.height = "6vh";
            textarea.style.height = `${textarea.scrollHeight + 3.5}px`;
        },
        formatReceived(received) {
            // Ensure proper handling of multiline equations
            let processed = received.replace(/\n\\\\\n/g, '\\\\'); // Fix line breaks in equations
            processed = processed.replace(/\n{2,}/g, '\n\n'); // Normalize multiple newlines
            
            const rawHtml = marked(processed);
            return DOMPurify.sanitize(rawHtml);
        },
        scrollToBottom() {
            this.$nextTick(() => {
                const container = this.$refs.chatOutput;
                if (container) {
                    container.scrollTop = container.scrollHeight;
                }
            });
        },
        typesetMath() {
            if (this.isTypesetting) return;
            this.isTypesetting = true;
            this.$nextTick(() => {
                if (window.MathJax && this.$refs.chatOutput) {
                    // Typeset the entire chat output instead of just the last element
                    window.MathJax.typesetPromise([this.$refs.chatOutput])
                        .catch((err) => console.error("MathJax error:", err))
                        .finally(() => (this.isTypesetting = false));
                } else {
                    this.isTypesetting = false;
                }
            });
        },
        throttledTypesetMath: throttle(function () {
            this.typesetMath();
        }, 1000),
        checkSpeechRecognitionSupport() {
            const SpeechRecognition =
                window.SpeechRecognition || window.webkitSpeechRecognition;
            if (SpeechRecognition) {
                this.speechRecognitionSupported = true;
                this.speechRecognition = new SpeechRecognition();
                this.speechRecognition.lang = "en-US";
                this.speechRecognition.interimResults = false;
                this.speechRecognition.maxAlternatives = 1;
                this.speechRecognition.addEventListener("result", this.onSpeechResult);
                this.speechRecognition.addEventListener("end", this.onSpeechEnd);
                this.speechRecognition.addEventListener("error", this.onSpeechError);
            } else {
                this.speechRecognitionSupported = false;
            }
        },
        toggleDictation() {
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            if (isSafari) {
                alert("Dictation is not supported in Safari. Please use a different browser.");
                return;
            }
            if (!this.speechRecognition) {
                alert("Speech recognition is not supported in this browser.");
                return;
            }
            if (this.isDictating) {
                this.speechRecognition.stop();
                this.isDictating = false;
            } else {
                this.speechRecognition.start();
                this.isDictating = true;
            }
        },
        onSpeechResult(event) {
            const transcript = Array.from(event.results)
                .map((result) => result[0].transcript)
                .join("");
            this.input += transcript;
        },
        onSpeechEnd() {
            this.isDictating = false;
        },
        onSpeechError(event) {
            console.error("Speech recognition error:", event.error);
            this.isDictating = false;
        },
    },
    watch: {
        currentInteractions: {
            handler() {
                this.throttledTypesetMath();
            },
            deep: true,
        },
        editingChatIndex(newVal) {
            if (newVal !== null) {
                this.$nextTick(() => {
                    const input = this.$refs.chatTitleInput;
                    if (input && input[0]) {
                        input[0].focus();
                        input[0].select();
                    }
                });
            }
        }
    },
    updated() {
        this.scrollToBottom();
        this.throttledTypesetMath();
    },
    mounted() {
        this.throttledTypesetMath();
    },
    beforeDestroy() {
        if (this.socket) {
            this.socket.disconnect();
        }
        if (this.speechRecognition) {
            this.speechRecognition.abort();
        }
    },
};
</script>

<style scoped>
.page-container {
    display: flex;
    flex-direction: column;
}

.beta-badge {
    color: var(--secondary);
    background-color: darkslategrey;
    text-align: center;
    padding: 0.4vw;
    margin-left: 0.3vw;
    border-radius: 0.5vw;
    font-size: 0.8vw;
}

/* Feedback modal */
.feedback-button {
    border-radius: 5vw;
    cursor: pointer;
    transition: ease all 0.3s;
    margin-right: 2vw;
}

.feedback-button:hover {
    color: var(--classi2);
}

.feedback-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedback-modal {
    background-color: #fff;
    padding: 2vh 2vw;
    width: 40vw;
    border-radius: 1vw;
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 80vh;
    overflow-y: auto;
}

.feedback-modal textarea {
    width: 100%;
    height: 20vh;
    resize: none;
    font-size: 1vw;
    padding: 1vh 1vw;
    margin-bottom: 2vh;
    border-radius: 0.5vw;
    border: 0.2vh solid var(--classi2);
    outline: none;
}

.feedback-modal button {
    align-self: flex-end;
    padding: 1vh 2vw;
    border-radius: 0.5vw;
    background-color: var(--classi2);
    color: #fff;
    border: none;
    font-size: 1vw;
    cursor: pointer;
}

.close {
    position: absolute;
    top: 1vh;
    right: 2vw;
    font-size: 2vw;
    cursor: pointer;
}

/* Header */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vh;
    height: 11vh;
    border-bottom: 0.2vh solid var(--classi2);
}

.logo {
    display: flex;
    align-items: center;
    font-size: 2.5vw;
    font-weight: 100;
    letter-spacing: 0.2vw;
    font-family: var(--accent-font);
    text-decoration: none;
}

.classi1 {
    color: var(--classi1);
    text-transform: none;
}

.classi2 {
    color: var(--classi2);
    text-transform: none;
}

.classi1:hover,
.classi2:hover {
    transform: translateY(-5%);
}

.school-logo {
    cursor: pointer;
    width: 7vw;
    border-radius: 2vw;
    background-color: transparent;
}

.logout-button {
    border-radius: 5vw;
    cursor: pointer;
    transition: ease all 0.3s;
}

.logout-button:hover {
    color: var(--classi2);
}

/* Sidebar */
.main-container {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.sidebar {
    background-color: #f4f4f4;
    width: 20vw;
    height: 88vh;
    margin-left: 1vh;
    border-right: 0.2vh solid #ccc;
    box-sizing: border-box;
    padding: 2vh;
    transition: width 0.3s;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}

.sidebar.collapsed {
    width: 4vw;
    min-width: 4vw;
}

.collapse-btn {
    position: absolute;
    top: 2vh;
    right: 2vh;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 1.6vw;
    z-index: 1;
    padding: 0.5vh;
}

.sidebar-header {
    font-weight: bold;
    margin: 1vh 0;
}

.chatList {
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 1;
    overflow-y: auto;
}

.chatList li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1vh 0;
    padding: 1vh;
    border-radius: 0.5rem;
    transition: background-color 0.2s;
    cursor: pointer;
}

.chatList li:hover {
    background-color: #ddd;
}

.chatList li.active {
    background-color: lightgrey;
}

.chatItem {
    flex: 1;
    font-size: 1vw;
    line-height: 2vh;
}

.chatActions {
    display: flex;
    gap: 1vw;
}

.rename-icon,
.delete-icon {
    font-size: 1.2vw;
    cursor: pointer;
    transition: ease all 0.3s;
}

.rename-icon:hover,
.delete-icon:hover {
    color: var(--classi2);
}

.newChatBtn {
    width: 100%;
    padding: 1vw;
    background-color: var(--classi2);
    color: #fff;
    border: none;
    border-radius: 1rem;
    cursor: pointer;
    font-size: 1vw;
    margin-top: 2vh;
    transition: background-color 0.2s;
}

.newChatBtn[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

.newChatBtn:hover {
    opacity: 0.8;
}

/* Chat content */
.contentArea {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.chatContainer {
    height: 85vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    border-radius: 2vw;
    font-size: 1.2vw;
}

.chatOutput {
    flex: 1;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 2vh 2vw 0vh 2vw;
    color: var(--secondary);
    border: none;
    border-radius: 1vw;
    text-align: left;
    font-size: 1.1vw;
    font-family: var(--main-font);
}

.initial-message {
    color: var(--primary);
    font-family: var(--accent-font);
}

.initial-message h1 {
    font-weight: 100;
    font-size: 2.5vw;
    padding-bottom: 0.5vh;
}

.initial-message .byline {
    font-size: 1.8vw;
    color: slategray;
}

.byline2 {
    font-family: var(--main-font);
    color: slategray;
    padding-top: 1.2vh;
    padding-left: 0.3vw;
}

.aura {
    color: var(--classi2);
}

.example-queries {
    min-width: 50vw;
    max-width: 80vw;
    width: fit-content;
    margin: 2vh 2vw;
    padding: 2vh 2vw;
    background-color: var(--accent1);
    border-radius: 2vw;
    line-height: 2rem;
}

.example-query {
    width: fit-content;
    cursor: pointer;
    list-style: none;
    padding: 0 1vw;
}

.example-query:hover {
    box-shadow: var(--box-shadow);
    border-radius: 0.8rem;
    color: var(--classi2);
    transition: ease all 0.3s;
}

.icon {
    margin-right: 1vw;
    color: gold;
}

/* Chat bubbles */
.receivedBox,
.sentBox {
    line-height: 2;
    width: fit-content;
    max-width: 80%;
    padding: 1.5vh 2vw;
    margin-bottom: 2vh;
    border-radius: 2rem;
}

.sentBox {
    margin-left: auto;
    margin-right: 0;
    background-color: var(--classi2);
    color: #fff;
}

.receivedBox {
    margin-left: 0;
    margin-right: auto;
    background-color: var(--accent1);
    color: #000;
}

.typing-indicator {
    display: flex;
    align-items: center;
}

.ellipsis {
    display: inline-block;
}

.ellipsis span {
    display: inline-block;
    font-size: 2vw;
    line-height: 0;
    animation: bounce 1s infinite;
}

.ellipsis span:nth-child(1) {
    animation-delay: 0s;
}

.ellipsis span:nth-child(2) {
    animation-delay: 0.2s;
}

.ellipsis span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes bounce {

    0%,
    60%,
    100% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(-2vh);
    }
}

/* Chat input row */
.inputRow {
    display: flex;
    width: 100%;
    padding: 2vh 2vh 0 2vh;
    box-sizing: border-box;
    align-items: center;
}

.inputWrapper {
    position: relative;
    width: 93%;
    height: 6vh;
}

textarea {
    font-family: var(--main-font);
    font-size: 1vw;
    max-height: 30vh;
}

.chatInput {
    position: absolute;
    bottom: 0;
    top: auto;
    transform-origin: bottom;
    width: 100%;
    height: 100%;
    border-radius: 1vw;
    padding: 1vh 2vw;
    box-sizing: border-box;
    resize: none;
    outline: none;
    overflow: auto;
    border: 0.2vh solid var(--classi2);
    padding-right: 3vw;
}

.chatInput:focus {
    outline: 0.3vh solid var(--classi2);
    box-shadow: var(--box-shadow);
}

.dictationButton {
    position: absolute;
    right: 2vw;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: var(--classi2);
    font-size: 1.8vw;
    transition: all ease 0.3s;
}

.dictationButton:hover {
    color: #000;
}

.dictationButton.recording {
    animation: pulse 1s infinite;
    color: red;
}

@keyframes pulse {
    0% {
        transform: translateY(-50%) scale(1);
    }

    50% {
        transform: translateY(-50%) scale(1.2);
    }

    100% {
        transform: translateY(-50%) scale(1);
    }
}

.sendButton {
    cursor: pointer;
    display: flex;
    width: 6%;
    height: 100%;
    background-color: var(--classi2);
    color: #fff;
    border: none;
    border-radius: 1vw;
    font-size: 1.5vw;
    align-items: center;
    justify-content: center;
    transition: all ease 0.3s;
    margin-left: 1vw;
}

.sendButton:hover,
.sendButton:active {
    opacity: 0.6;
}

.chat-title-input {
    width: 100%;
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--classi2);
    font-size: 1vw;
    padding: 0.2vh 0.5vw;
    outline: none;
    font-family: var(--main-font);
}

.chat-title-input:focus {
    border-bottom: 2px solid var(--classi2);
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

/* Make the spinner smaller for this use case */
:deep(.spinner) {
    width: 3rem;
    height: 3rem;
    border-width: 0.3rem;
}
</style>